<template>
  <div class="modal fade " id="storeManagerStatusUpdateModal" tabindex="-1" role="dialog"
       aria-labelledby="storeManagerStatusUpdateModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Status Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">

          <div class="row">

            <div class="col-12 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Store Manager Status</label>
                  <div class="" style="margin-top: .5rem">
                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                      <div>
                        <input v-model="managerStoreManagerStatus" type="checkbox" class="custom-control-input" checked
                               id="employeeTeamLeadStatusCheckbox">
                        <label class="custom-control-label mr-1" for="employeeTeamLeadStatusCheckbox"></label>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateOtherInformation">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  mixins: [ShowToastMessage],
  name: "StoreManagerStatusUpdateModal",
  props: ['manager'],
  emits: ['getSingleManager'],
  components: {},
  data() {
    return {
      errors: {},
      user_id: '',
      manager_id: '',
      employee_id: '',

      managerStoreManagerStatus: false,

      storeManager: {
        id: '',
        status: '',

      },

    }
  },
  watch: {
    manager(currentManager) {
      this.user_id = currentManager.user.id;
      this.employee_id = currentManager.employee.id;
      this.manager_id = currentManager.id;
      this.storeManager.id = currentManager?.storeManager?.id ?? null;
      this.storeManager.status = currentManager?.storeManager?.status ?? null;
      this.managerStoreManagerStatus = currentManager?.storeManager?.status === 'Active' ? true : false;

      console.log(currentManager);
    },


  },
  methods: {
    ...mapActions({
      putStoreManager: 'appStoreManagers/putStoreManager',
      postStoreManager: 'appStoreManagers/postStoreManager',

    }),

    async updateOtherInformation() {

      if (this.storeManager.id) {

        const dataObj = {
          id: this.storeManager.id,
          data: {
            status: this.managerStoreManagerStatus ? 1 : 0,
          },
        };
        const response = await this.putStoreManager(dataObj);


        if (response.status === 200 || response.status === 201) {
          document.querySelector('[data-target="#storeManagerStatusUpdateModal"]').click();

          this.$emit('getSingleManager', this.$route.params.id);

          this.errors = {};
          this.showToastMessage(response.message);
        } else {
          this.errors = response.errors;
        }
        if (response.message) {
          this.showToastMessage(response);
        }

      } else {

        const postStoreManagerData = {
          user_id: this.user_id,
          employee_id: this.employee_id,
          manager_id: this.manager_id,
          status: this.managerStoreManagerStatus ? 1 : 0
        };
        const response = await this.postStoreManager(postStoreManagerData);


        if (response.status === 200 || response.status === 201) {
          document.querySelector('[data-target="#storeManagerStatusUpdateModal"]').click();

          this.$emit('getSingleManager', this.$route.params.id);

          this.errors = {};
          this.showToastMessage(response.message);
        } else {
          this.errors = response.errors;
        }
        if (response.message) {
          this.showToastMessage(response);
        }

      }

    },

  },

}
</script>

<style>

</style>
