<template>
  <div class="modal fade " id="employeeInfoUpdateModal" tabindex="-1" role="dialog" aria-labelledby="UserUpdateModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Employee Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row ">
              <div>
                <h5>ID Information</h5>
                <div class="row ">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Designation</label>
                      <input v-model="employeeInformationData.designation" type="text" class="form-control "
                             placeholder="Enter designation">
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>ID Number</label>
                      <input v-model="employeeInformationData.id_number" type="text" class="form-control "
                             placeholder="Enter a unique ID">
                    </div>
                  </div>
                </div>
                <h5>Salary Information</h5>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Salary Type</label>
                      <VueMultiselect v-model="selectedSalaryType" class="" :options="salaryType"
                                      :close-on-select="true" placeholder="Select state" label="name" track-by="value"
                                      :show-labels="false"/>

                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Salary Amount</label>
                      <input v-model="salary_amount" type="number" min="0.00" step="0.001"
                             class="form-control "
                             placeholder="Enter salary amount">
                    </div>
                  </div>
                </div>
                <h5>Other Information</h5>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>ABN</label>
                      <input v-model="employeeInformationData.abn" type="text" class="form-control "
                             placeholder="Enter ABN">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateEmployeeInformation">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "EmployeeUpdateModal",
  emits: ['updatedEmployeeData'],
  props: ['employee'],
  mixins: [ShowToastMessage],
  data() {
    return {
      currentSalary: '',
      employeeId: '',
      employeeInformationData: {
        address_id: '',
        user_id: '',
        abn: '',
        id_number: '',
        designation: '',
        salary_type: 0,
        salary_amount: 0.00,
        status: 1,
      },
      employeeSalaryAmount: 0.00,
      selectedSalaryType: {
        value: '',
        name: 'Any',
      },
      salaryType: [
        {
          value: 0,
          name: 'Fixed',

        },
        {
          value: 1,
          name: 'Hourly',
        },
      ],

      errors: {
        employee: {
          addressId: '',
          userId: '',
          abn: '',
          idNumber: '',
          designation: '',
          salaryType: '',
          salaryAmount: '',
        },
      },
      salary_amount: '',
    }
  },
  watch: {
    employee: {
      handler(value) {
        console.log(value)
        if (value.salaryType) {
          let typeIndex = this.salaryType.findIndex(item => item.name === value.salaryType);
          if (typeIndex !== -1) {
            let type = this.salaryType[typeIndex];
            this.selectedSalaryType = {
              value: type.value,
              name: type.name
            };
          }
        }
        this.salary_amount = parseFloat(value.salaryAmount / 100).toFixed(2) ?? 0.00,
            this.employeeInformationData = {
              designation: value.designation,
              user_id: value.userId,
              address_id: value.addressId,
              id_number: value.idNumber,
              abn: value.abn,
            }
        this.employeeId = value.id
      },
    },

    selectedSalaryType() {
      this.employeeInformationData.salary_type = this.selectedSalaryType.value;
    },
    salary_amount: {
      handler(current) {
        this.employeeInformationData.salary_amount = current * 100;
      }
    }

  },
  methods: {
    ...mapActions({
      putEmployee: 'appEmployees/putEmployee'
    }),
    async updateEmployeeInformation() {

      const dataObj = {
        id: this.employeeId,
        data: this.employeeInformationData,
      };
      const response = await this.putEmployee(dataObj);
      if (response.status === 200 || response.status === 201) {
        document.querySelector('[data-target="#employeeInfoUpdateModal"]').click();

        this.$emit('updatedEmployeeData', this.employeeInformationData);

        this.errors = {};
        this.showToastMessage(response.message);
      } else {
        this.errors = response.errors;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
    },

    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  }
}
</script>

<style scoped>

</style>