<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Manager/View</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>

        <div class="content-body">
          <section class="page-user-profile">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="d-block d-sm-flex mb-2 align-items-center">
                      <div class="mr-2 position-relative">
                        <img :src="employee.avatar" alt="users avatar" class="users-avatar-shadow rounded-circle"
                             height="120" width="120">
                        <div
                            class="position-absolute update-image-avatar d-flex align-items-center justify-content-center"
                            @click.capture="openImageUpdateModal()">
                          <i class='bx bxs-camera'></i>
                        </div>
                      </div>
                      <div class="media-body ">
                        <h4 class="media-heading text-capitalize">{{ userFullName }}</h4>
                        <h6 v-if="user.role.name" class="pb-1">{{ `${user.role.type} / ${user.role.name}` }}</h6>
                        <h6 class="badge" :class="status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                          {{ status }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!--                   user information-->
                  <div class="col-lg-3">
                    <div class="card ">
                      <div class="card-body">
                        <h5 class="mb-1">User Information <span style="cursor: pointer;" data-toggle="modal"
                                                                data-target="#userDataUpdateModal" title="Edit"
                                                                class="single-edit-btn text-primary"><i
                            class='bx bx-edit-alt'></i></span></h5>
                        <ul class="list-unstyled">
                          <li class="mb-1 text-capitalize">
                            <span class="text-bold-700 mb-1" style="text-transform: capitalize;">First Name: </span>
                            <span>{{ user.firstName }}</span>
                          </li>
                          <li class="mb-1 text-capitalize">
                            <span class="text-bold-700 mb-1" style="text-transform: capitalize;">Last Name: </span>
                            <span>{{ user.lastName }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Phone Number: </span>
                            <span>{{ user.phoneNumber || 'Not Provided' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Email: </span>
                            <span>{{ user.email || 'Not Provided' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Date Of Birth: </span>
                            <span>{{ user.dateOfBirth || 'Not Provided' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Created at: </span>
                            <span>{{ createdAt }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Last Updated at: </span>
                            <span>{{ updatedAt }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!--                   role and address information-->
                  <div class="col-lg-4 mb-4">
                    <div class="card">
                      <div class="card-body">
                        <div>
                          <h5 class="card-title">Role Information <span style="cursor: pointer;" data-toggle="modal"
                                                                        data-target="#userRoleDataUpdateModal"
                                                                        title="Edit"
                                                                        class="single-edit-btn text-primary"><i
                              class='bx bx-edit-alt'></i></span></h5>
                          <div v-if="user.role.name" class="row">
                            <div class="col-sm-6 col-12">
                              <h6><small class="text-muted pb-0">TYPE:</small></h6>
                              <p>{{ user.role.type }}</p>
                            </div>
                            <div class="col-sm-6 col-12 mt-1 mt-md-0">
                              <h6><small class="text-muted">NAME:</small></h6>
                              <p>{{ user.role.name }}</p>
                            </div>
                          </div>
                          <div v-else>
                            <p>No Role Assigned</p>
                          </div>
                        </div>
                        <hr class="my-2">
                        <div>
                          <h5 class="card-title">Address Information <span style="cursor: pointer;" data-toggle="modal"
                                                                           data-target="#addressUpdateModal"
                                                                           title="Edit"
                                                                           class="single-edit-btn text-primary"><i
                              class='bx bx-edit-alt'></i></span></h5>

                          <div v-if="employee.address.street" class="row">
                            <div class="col-sm-12 col-12">
                              <h6><small class="text-muted">FULL ADDRESS: </small></h6>
                              <p>{{ userFullAddress }}</p>
                            </div>
                          </div>
                          <div v-else>
                            <p>No Address Assigned</p>
                          </div>
                        </div>
                        <hr class="my-2">
                        <div>
                          <h5 class="card-title">Store Manager Information</h5>
                          <div class="row">
                            <div v-if="manager.storeManager?.id" class="col-12 mt-1 mt-md-0">
                              <h6><small class="text-muted">STORE MANAGER STATUS: </small>
                                <span
                                    style="cursor: pointer;" data-toggle="modal"
                                    data-target="#storeManagerStatusUpdateModal" title="Edit"
                                    class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i>
                                </span>
                              </h6>
                              <p class="badge mr-1"
                                 :class="manager.storeManager.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'"
                                 style="padding: 4px;">{{ manager.storeManager.status }}</p>
                            </div>
                            <div v-else class="col-12 mt-1 mt-md-0">
                              <p>Not Assigned As Store Manager
                                <span style="cursor: pointer;" data-toggle="modal"
                                      data-target="#storeManagerStatusUpdateModal"
                                      title="Edit"
                                      class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                   employee and bank details information-->
                  <div class="col-lg-5 mb-4">
                    <div class="card">
                      <div class="card-body">
                        <div>
                          <h5 class="card-title">Employee Information
                            <span style="cursor: pointer;" @click="openEmployeeUpdateModal()" title="Edit"
                                  class="single-edit-btn text-primary"><i
                                class='bx bx-edit-alt'></i></span></h5>
                          <div v-if="employee.designation" class="row">
                            <div class="col-sm-6 col-12 text-capitalize">
                              <h6><small class="text-muted pb-0">Designation:</small></h6>
                              <p>{{ employee.designation }}</p>
                            </div>
                            <div class="col-sm-6 col-12 mt-1 mt-md-0">
                              <h6><small class="text-muted">ID Number:</small></h6>
                              <p>{{ employee.idNumber }}</p>
                            </div>
                            <div class="col-sm-6 col-12 mt-1 mt-md-0">
                              <h6><small class="text-muted">Salary amount:</small></h6>
                              <p>{{ parseFloat(employee.salaryAmount / 100).toFixed(2) }}</p>
                            </div>
                            <div class="col-sm-6 col-12 mt-1 mt-md-0">
                              <h6><small class="text-muted">Salary type:</small></h6>
                              <p>{{ employee.salaryType }}</p>
                            </div>
                            <div class="col-sm-6 col-12 mt-1 mt-md-0">
                              <h6><small class="text-muted">ABN:</small></h6>
                              <p>{{ employee.abn }}</p>
                            </div>
                          </div>
                          <div v-else>
                            <p>No Employee Data Provided</p>
                          </div>
                        </div>
                        <hr class="my-2">
                        <div>
                          <h5 class="card-title">Bank Information
                            <span style="cursor: pointer;" data-toggle="modal"
                                  data-target="#bankInfoUpdateModal" title="Edit"
                                  class="single-edit-btn text-primary"><i
                                class='bx bx-edit-alt'></i></span>
                          </h5>
                          <div v-if="user.bankAccount.accountName && user.bankAccount.accountNumber" class="row">
                            <div class="col-sm-12 col-12 pb-0">
                              <h6><small class="text-muted">Bank Name: </small></h6>
                              <p>{{ user.bankAccount.bankName }}</p>
                            </div>
                            <div class="col-sm-12 col-12">
                              <h6><small class="text-muted">Account Name: </small></h6>
                              <p>{{ user.bankAccount.accountName }}</p>
                            </div>
                            <div class="col-sm-12 col-12">
                              <h6><small class="text-muted">Account NUmber: </small></h6>
                              <p>{{ user.bankAccount.accountNumber }}</p>
                            </div>
                            <div class="col-sm-12 col-12">
                              <h6><small class="text-muted">BSB: </small></h6>
                              <p>{{ user.bankAccount.bsb }}</p>
                            </div>
                          </div>
                          <div v-else>
                            <p>NO BANK INFO PROVIDED</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <UserUpdateModal
              modal-name="userDataUpdateModal"
              :modal-usages-for="1"
              :user-id="user.id"
              :user-data="{...user}"
              @updatedUserData="onUpdatedUserData"/>
          <UserUpdateModal
              modal-name="userRoleDataUpdateModal"
              :modal-usages-for="2"
              :user-id="user.id"
              :get-roles-params="getRolesParams"
              :selected-role-data="{value:user.role.id,name:user.role.name}"
              :user-role-id="user.role.id"
              :user-data="{...user}"
              @updatedUserRoleData="onUpdatedUserRoleData"/>
          <div class="" data-toggle="modal" data-target="#employeeDetailsUpdateModal"></div>
          <EmployeeDetailsUpdateModal v-on:getSingleView="getSingleManager($event)"
                                      :existing-employee="{...manager?.employee}"/>
          <AddressUpdateModal
              modal-name="addressUpdateModal"
              address-update-for-model-name="employee"
              :model-id="id"
              :user-id="user.id"
              :selected-address-data="selectedAddressData"
              @updateModalAddressData="updateModalAddressData"/>
          <div class="" data-toggle="modal" data-target="#updateAvatarModal"></div>
          <ImageUpdateModal v-on:getSingleView="uploadImage($event)"
                            :previousImage="employee.avatar"/>
          <BankInfoUpdateModal v-on:updatedBankData="onUpdatedBankData($event)" :user="{...user}"/>
          <EmployeeUpdateModal v-on:updatedEmployeeData="onUpdatedEmployeeData($event)" :employee="{...employee}"/>
          <StoreManagerStatusUpdateModal v-on:getSingleManager="getSingleManager($event)" :manager="{...manager}"/>
          <!--          <UserUpdateModal v-on:getUpdatedUserData="updateUser($event,data)" :user="{...user}"/>-->
          <!--          <AddressUpdateModal v-on:getSingleAdmin="getSingleManager($event)" :user="{...user}" :states="states"/>-->
          <!--          <RoleUpdateModal v-on:getSingleAdmin="getSingleManager($event)" :user="{...user}" :roles="role"/>-->

        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import AddressUpdateModal from "@/components/backEnd/modal/AddressUpdateModal";

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// core package
import {mapActions, mapGetters} from "vuex";

import EmployeeUpdateModal from "@/views/backEnd/users/managers/includes/EmployeeUpdateModal";
import BankInfoUpdateModal from "@/components/backEnd/modal/BankInfoUpdateModal";
import UserUpdateModal from "@/components/backEnd/modal/UserUpdateModal";
import ImageUpdateModal from "@/components/backEnd/modal/ImageUpdateModal";
import EmployeeDetailsUpdateModal from "@/views/backEnd/users/technicians/includes/EmployeeDetailsUpdateModal";
import StoreManagerStatusUpdateModal from "@/views/backEnd/users/managers/includes/StoreManagerStatusUpdateModal";

export default {
  name: "ManagerView",

  mixins: [ShowToastMessage, Loader],

  components: {
    EmployeeDetailsUpdateModal,
    AppLayout,
    UserUpdateModal,
    /*AddressUpdateModal,*/
    /*RoleUpdateModal,*/
    EmployeeUpdateModal,
    BankInfoUpdateModal,
    ImageUpdateModal,
    AddressUpdateModal,
    StoreManagerStatusUpdateModal,
  },
  data() {
    return {
      getSettingsParams: {
        type: ['default'],
        key: ['default_state'],
      },
      getRolesParams: {
        type: 3
      },
      id: '',
      status: '',
      createdAt: '',
      updatedAt: '',
      user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        avatar: '',
        role: {
          id: '',
          type: '',
          name: '',
          description: '',
        },
        bankAccount: {
          accountName: '',
          accountNumber: '',
          bankName: '',
          bsb: '',
          id: '',
          userId: ''
        },
        superAdmin: {
          id: '',
          superAdminStatus: '',
        },
      },
      employee: {
        id: '',
        abn: '',
        idNumber: '',
        designation: '',
        salaryType: '',
        salaryAmount: '',
        avatar: '',
        address: {
          id: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        teamLead: {
          id: '',
          status: '',
        }
      },
      storeManager: {
        id: '',
        status: '',
      },

      getManagerParams: {
        with_relation: [
          'user',
          'user.role',
          'user.media',
          'user.bankAccount',
          'employee',
          'employee.media',
          'employee.address',
          'storeManager'
        ],
      },
      storeManagerStatus: false,
      modelForUpdating: {},
    }
  },
  watch: {
    storeManager: {
      handler(currentValue) {
        console.log(currentValue);
        this.storeManagerStatus = currentValue.status === 'Active';
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      manager: 'appManagers/manager'
    }),
    /*storeManagerStatus: {
      get: function () {
        return this.storeManager.status === 'Active';
      },
      set: function (newValue) {
        this.updateStoreManagerStatus(newValue);
      }
    },*/

    userFullName() {
      return `${this.user.firstName} ${this.user.lastName}`;
    },
    userFullAddress() {
      return `${this.employee.address.street}, ${this.employee.address.suburb}, ${this.employee.address.state.toUpperCase()},  ${this.employee.address.postCode}, ${this.employee.address.country}`;
    },
    selectedAddressData() {
      return {
        value: this.employee.address.id,
        name: this.userFullAddress,
      };
    },
    states() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },

    role() {
      let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;
        return {value: roleId, name: roleName};
      });
      return [{value: '', name: 'None'}, ...activeRoles];
    },
  },
  methods: {

    ...mapActions({
      getManager: 'appManagers/getManager',
      resetManager: 'appManagers/resetManager',
      getSettings: 'appSettings/getSettings',
      getRoles: 'appRoles/getRoles',
      putStoreManager: 'appStoreManagers/putStoreManager',
      putAvatar: 'appEmployees/putAvatar',
    }),
    async updateModalAddressData(updateModalAddressData) {
      this.employee.address.id = updateModalAddressData.addressData.id;
      this.employee.address.street = updateModalAddressData.addressData.street;
      this.employee.address.suburb = updateModalAddressData.addressData.suburb;
      this.employee.address.state = updateModalAddressData.addressData.state;
      this.employee.address.postCode = updateModalAddressData.addressData.postCode;
      this.employee.address.country = updateModalAddressData.addressData.country;
    },
    openEmployeeUpdateModal() {
      document.querySelector('[data-target="#employeeDetailsUpdateModal"]').click();
    },

    openImageUpdateModal() {
      document.querySelector('[data-target="#updateAvatarModal"]').click();
    },
    async uploadImage(imageBase64) {

      const payLoad = {
        id: this.employee.id,
        data: {
          avatar: imageBase64
        }
      }
      const response = await this.putAvatar(payLoad);
      if (response.status === 201 || response.status === 200) {
        this.loader(false);
        document.querySelector('[data-target="#updateAvatarModal"]').click();
        await this.getSingleManager(this.$route.params.id)
        // this.$emit('getSingleView', this.$route.params.id);

      }
      if (response && response.message) {
        this.showToastMessage(response);
      }
    },
    async onConfirmStoreManagerStatusUpdating(updatedData) {
      await this.updateStoreManagerStatus(updatedData);
    },
    async updateStoreManagerStatus(updatedData) {
      let updateStatus = updatedData.modelForUpdating.status === 'Active' ? 0 : 1
      let dataObj = {
        id: updatedData.modelForUpdating.modelId,
        data: {
          status: updateStatus,
        },
      };
      this.putStoreManager(dataObj).then(async (response) => {
        if (response.status === 200) {
          this.storeManager.status = updateStatus === 1 ? 'Active' : 'Inactive';
          let toastObj = {message: 'Store Manager Info Updated', type: 'success'};
          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    },
    async onUpdatedEmployeeData(updateEmployeeData) {
      this.employee = {
        id: this.employee.id,
        userId: this.user.id,
        addressId: this.employee.address.id,
        designation: updateEmployeeData.designation,
        idNumber: updateEmployeeData.id_number,
        abn: updateEmployeeData.abn,
        salaryAmount: updateEmployeeData.salary_amount,
        salaryType: updateEmployeeData.salary_type
      }
      console.log(updateEmployeeData);
    },
    async onUpdatedBankData(updatedBankData) {
      this.user.bankAccount = {
        accountName: updatedBankData.account_name,
        accountNumber: updatedBankData.account_number,
        bankName: updatedBankData.bank_name,
        bsb: updatedBankData.bsb,
        id: '',
        userId: this.user.id
      }
      console.log(updatedBankData);
    },
    async onUpdatedUserRoleData(updatedUserRoleData) {
      console.log(updatedUserRoleData);
    },
    async onUpdatedUserData(updatedUserData) {
      this.user.firstName = updatedUserData.userData.firstName;
      this.user.lastName = updatedUserData.userData.lastName;
      this.user.email = updatedUserData.userData.email;
      this.user.phoneNumber = updatedUserData.userData.phoneNumber;
      this.user.dateOfBirth = updatedUserData.userData.dateOfBirth;
    },
    async getAdminTypeRoleList() {
      await this.getRoles(this.getRolesParams);
    },
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
    async getSingleManager(id) {
      this.loader(true);
      let paramObj = {
        id: id,
        params: this.getManagerParams,
      };

      await this.getManager(paramObj).then(async (response) => {
        this.loader(false);
        if (response.status === 200) {
          this.id = this.manager.id ? this.manager.id : '';
          this.status = this.manager.status ? this.manager.status : '';
          this.createdAt = this.manager.created_at ? this.manager.created_at : '';
          this.updatedAt = this.manager.updated_at ? this.manager.updated_at : '';

          this.user.id = this.manager.user && this.manager.user.id ? this.manager.user.id : '';
          this.user.firstName = this.manager.user && this.manager.user.first_name ? this.manager.user.first_name : '';
          this.user.lastName = this.manager.user && this.manager.user.last_name ? this.manager.user.last_name : '';
          this.user.email = this.manager.user && this.manager.user.email ? this.manager.user.email : '';
          this.user.phoneNumber = this.manager.user && this.manager.user.phone_number ? this.manager.user.phone_number : '';
          this.user.dateOfBirth = this.manager.user && this.manager.user.date_of_birth ? this.manager.user.date_of_birth : '';
          this.user.avatar = this.manager.user && this.manager.user.avatar ? this.manager.user.avatar : '';

          this.user.role.id = this.manager.user && this.manager.user.role && this.manager.user.role.id ? this.manager.user.role.id : '';
          this.user.role.type = this.manager.user && this.manager.user.role && this.manager.user.role.type ? this.manager.user.role.type : '';
          this.user.role.name = this.manager.user && this.manager.user.role && this.manager.user.role.name ? this.manager.user.role.name : '';
          this.user.role.description = this.manager.user && this.manager.user.role && this.manager.user.role.description ? this.manager.user.role.description : '';

          this.employee.id = this.manager?.employee?.id ?? '';
          this.employee.avatar = this.manager.employee && this.manager.employee.avatar ? this.manager.employee.avatar : '';
          this.employee.abn = this.manager?.employee?.abn ?? '';
          this.employee.idNumber = this.manager?.employee?.id_number ?? '';
          this.employee.designation = this.manager?.employee?.designation ?? '';
          this.employee.salaryType = this.manager?.employee?.salary_type ?? '';
          this.employee.salaryAmount = this.manager?.employee?.salary_amount ?? '';

          this.employee.address.id = this.manager?.employee?.address?.id ?? '';
          this.employee.address.street = this.manager?.employee?.address?.street ?? '';
          this.employee.address.suburb = this.manager?.employee?.address?.suburb ?? '';
          this.employee.address.state = this.manager?.employee?.address?.state ?? '';
          this.employee.address.postcode = this.manager?.employee?.address?.post_code ?? '';
          this.employee.address.country = this.manager?.employee?.address?.country ?? '';

          this.employee.teamLead.id = this.manager?.employee?.teamLead?.id ?? '';
          this.employee.teamLead.status = this.manager?.employee?.teamLead?.status ?? '';

          this.user.bankAccount = {
            accountName: this.manager?.user?.bankAccount?.account_name ?? '',
            accountNumber: this.manager?.user?.bankAccount?.account_number ?? '',
            bankName: this.manager?.user?.bankAccount?.bank_name ?? '',
            bsb: this.manager?.user?.bankAccount?.bsb ?? '',
            id: this.manager?.user?.bankAccount?.id ?? '',
            userId: this.user.id
          }

          this.storeManager = {
            id: this.manager?.storeManager?.id ?? '',
            status: this.manager?.storeManager?.status ?? '',
          }
        }

        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

  },
  async mounted() {
    this.loader(true);
    await this.getSettingList();
    const routeParams = await this.$route.params;
    await this.getSingleManager(routeParams.id);
    // await this.getAdminTypeRoleList();
  },
  async beforeUnmount() {
    await this.resetManager();
  },
}
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.update-image-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #5a8dee;
  border: 2px solid white;
  top: 79%;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.update-image-avatar:hover {
  background-color: #4173ce;
}

.update-image-avatar > i {
  font-size: 20px;
  z-index: 10;
  color: white;
}

@media only screen and (max-width: 576px) {
  .update-image-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #5a8dee;
    border: 2px solid white;
    top: 64%;
    left: 92px;
    right: 0;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}
</style>
